<template>
  <section class="bottomBox section" id="bottomBox">
    <div class="container_center">
      <div class="bottomBox__content">
        <div class="bottomBox__left"><img class="bottom__logo" src="images/logo.png" alt=""></div>
        <div class="bottomBox__center">
          <div class="feedback-btn-wrapper">
            <a href="https://t.me/poisk_films_support" class="feedback-btn">чат поддержки</a>
          </div>
        </div>
        <div class="bottomBox__right">
          <div class="tg__bot">ТЕЛЕГРАМ-БОТ</div>
          <div class="bot__name">GOGIBEY</div>
          <div class="tg__desc">© все права защищены</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ButtonSupport',
}
</script>
