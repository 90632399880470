<template>
  <div class="tariffs-content__card-wrapper">
    <div class="tariffs-content__card">
      <h3 class="tariffs-content__card-title">Управление тарифом</h3>
      <div class="tariffs-content__card-info">
        <p class="tariffs-content__card-name"><b>GO!</b> {{ tariffName }}</p>
        <p class="tariffs-content__card-hint">тариф активен</p>
        <p class="tariffs-content__card-date">действует до: {{ tariffEndDate }}</p>
      </div>
      <div class="tariffs-content__card-actions">
        <a v-on:click="disableSubscribe" href="#0">отключить</a>
        <!-- <a href="#0">изменить</a> -->
        <router-link 
          v-bind:to="{
            path: '/profile-list-tariffs',
            query: {
              id,
              first_name: firstName,
              last_name: lastName,
              photo_url: image,
              username: name,
              auth_date: authDate,
              hash,
            }
          }" 
        >
          изменить
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import router from '@/router'
export default {
  name: 'ControlTariff',
  computed: {
    ...mapState({
      firstName: state => state.userInfo.firstName,
      lastName: state => state.userInfo.lastName,
      image: state => state.userInfo.image,
      name: state => state.userInfo.name,
      id: state => state.userInfo.id,
      authDate: state => state.userInfo.authDate,
      hash: state => state.userInfo.hash,
      tariffName: state => state.userTariff.tariffName,
      tariffEndDate: state => state.userTariff.tariffEndDate,
    }),
  },
  methods: {
    ...mapActions({
      sendDisableSubscribe: 'userTariff/sendDisableSubscribe',
    }),
    async disableSubscribe() {
      await this.sendDisableSubscribe();
      router.push({
        path: '/profile',
        query: {
          id: this.id,
          first_name: this.firstName,
          last_name: this.lastName,
          photo_url: this.image,
          username: this.name,
        },
      });
    },
  }
}
</script>

