<template>
  <footer class="footer">
    <section class="footer section" id="footer">
      <div class="container_center">
        <div class="footer__content">
          <ul class="footer__navbar">
          <li class="footer__item"><a class="footer__link" href="/pdf/politic.pdf" target="_blank">Политика конфиденциальности</a></li>
          <li class="footer__item"><a class="footer__link" href="/pdf/oferta.pdf" target="_blank">Публичная оферта</a></li>
          <li class="footer__item"><a class="footer__link" href="/pdf/services.pdf" target="_blank">Услуги и стоимость</a></li>
          </ul>
          <p class="footer__link">© GoGiBeybot 2022 г.</p>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',
}
</script>
