import router from '@/router';
import axios from 'axios';
import moment from 'moment';

moment.locale('ru');

const serviceApi = process.env.VUE_APP_SITE_API;

export const userTariff = {
  state: () => {
    return {
      userId: 0, // TODO Это значение надо брать только если прошла авторизация!
      tariffStatus: '',
      tariffName: '',
      tariffPrice: 0,
      tariffEndDate: null,
      tariffReceived: false,
    };
  },
  mutations: {
    setUserId(state, userId) {
      state.userId = userId;
    },
    setTariffStatus(state, tariffStatus) {
      state.tariffStatus = tariffStatus;
    },
    setTariffName(state, tariffName) {
      state.tariffName = tariffName;
    },
    setTariffPrice(state, tariffPrice) {
      state.tariffPrice = tariffPrice;
    },
    setTariffEndDate(state, tariffEndDate) {
      state.tariffEndDate = tariffEndDate;
    },
    setTariffReceived(state, tariffReceived) {
      state.tariffReceived = tariffReceived;
    },
  },
  actions: {
    async fetchTariff({state, commit}) {
      if (!state.tariffReceived) {
        const dataFromBase = (await axios.get(serviceApi, {
          params: {
            target: 'get-data',
            userId: router.currentRoute.value.query.id,
          },
        })).data;
        commit('setUserId', router.currentRoute.value.query.id);
        commit('setTariffStatus', dataFromBase.tariffStatus);
        commit('setTariffName', dataFromBase.tariffName);
        commit('setTariffPrice', dataFromBase.tariffPrice);
        commit('setTariffEndDate', moment(dataFromBase.tariffEndDate).format('DD.MM.YYYY'));
        commit('setTariffReceived', true);
      }
    },
    async sendDisableSubscribe({commit, state}) {
      const result = await axios.get(serviceApi, {
        params: {
          target: 'set-disable-subscribe',
          userId: state.userId,
        }
      });
      if (result.data.result === true) {
        commit('setTariffStatus', '');
        commit('setTariffName', '');
        commit('setTariffPrice', 0);
        commit('setTariffEndDate', null);
      }
    },
  },
  namespaced: true,
}
