import router from '@/router';
import axios from 'axios';

const serviceApi = process.env.VUE_APP_SITE_API;

// const tg = window.Telegram.WebApp;
// console.log(tg);
// tg.expand();
// const initDataUnsafeString = tg.initDataUnsafe;
// console.log(initDataUnsafeString);
// this.tempId = initDataUnsafeString.user.id;


export const userInfo = {
  state: () => {
    return {
      fullName: '',
      firstName: '',
      lastName: '',
      name: '',
      id: 0,
      image: '',
      authDate: '',
      hash: '',
      isAuth: undefined,
      profileUrl: '',
    };
  },
  getters: {    
    fullName(state) {
      return `${state.firstName} ${state.lastName}`
    },
    profileUrl(state) {
      let url = '/profile';
      url += `?id=${state.id}`;
      if (state.firstName) url += `&first_name=${state.firstName}`;
      if (state.lastName) url += `&last_name=${state.lastName}`;
      if (state.image) url += `&photo_url=${state.image}`;
      url += `&username=${state.name}`;
      url += `&auth_date=${state.authDate}`;
      url += `&hash=${state.hash}`;
      return url;
    },
  },
  mutations: {
    setFirstName(state, firstName) {
      state.firstName = firstName;
    },
    setLastName(state, lastName) {
      state.lastName = lastName;
    },
    setName(state, name) {
      state.name = name;
    },
    setId(state, id) {
      state.id = id;
    },
    setImage(state, image) {
      state.image = image;
    },
    setAuthDate(state, authDate) {
      state.authDate = authDate;
    },
    setHash(state, hash) {
      state.hash = hash;
    },
    setIsAuth(state, isAuth) {
      state.isAuth = isAuth;
    },
  },
  actions: {
    async fetchInfo({state, commit}) {
      if(!state.auth) {
        const query = router.currentRoute.value.query;

        let string = `auth_date=${query.auth_date}`;
        query.first_name ? string += `\nfirst_name=${query.first_name}` : '';
        string += `\nid=${query.id}`;
        string += query.last_name ? `\nlast_name=${query.last_name}` : '';
        string += query.photo_url ? `\nphoto_url=${query.photo_url}` : '';
        string += query.username ? `\nusername=${query.username}` : '';
        
        const checkHash = (await axios(serviceApi, {
          params: {
            target: 'check-hash',
            string,
            hash: query.hash,
            userId: query.id,
          }
        })).data;
        
        if (checkHash) {
          if (query.photo_url) commit('setImage', query.photo_url);
          if (query.first_name) commit('setFirstName', query.first_name);
          if (query.last_name) commit('setLastName', query.last_name);
          commit('setName', query.username);
          commit('setId', Number(query.id));
          commit('setAuthDate', Number(query.auth_date));
          commit('setHash', query.hash);
          commit('setIsAuth', true);
        }
      }
    },
  },
  namespaced: true,
}
