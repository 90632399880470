import { createStore } from 'vuex';
import { userInfo } from '@/store/UserInfo';
import { userTariff } from '@/store/UserTariff';

export default createStore({
  modules: {
    userInfo,
    userTariff,
  }
})
