<template>
  <PageHeader />

  <main class="main">
    
    <UserInfo />

    <div class="tariffs-content tariffs-content--empty">
      <div class="container_center">
        <div class="tariffs-content__body">
          <TariffYes />
          <ControlTariff /> 
        </div>
      </div>
    </div>

    <div class="tariffs-slogan tariffs-slogan--empty"></div>

    <ButtonSupport />
  </main>

  <PageFooter/>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import UserInfo from '@/components/UserInfo.vue';
import TariffYes from '@/components/TariffYes.vue';
import ControlTariff from '@/components/ControlTariff.vue';
import ButtonSupport from '@/components/ButtonSupport.vue';
import PageFooter from '@/components/PageFooter.vue';

export default {
  name: 'ManageTariff',

  components: {
    PageFooter,
    UserInfo,
    TariffYes,
    ControlTariff,
    ButtonSupport,
    PageHeader,
  },
  mounted() {
    document.body.classList.add('page-profile');
    document.body.classList.add('page-profile--control');
  },
  unmounted() {
    document.body.classList.remove('page-profile--control');
    document.body.classList.remove('page-profile--control');
  },
}
</script>
