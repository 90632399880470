<template>
  <div class="tariffs-content__list"> 
    <div class="tariffs-content__list-item">
      <a v-bind:onclick="pay76" href="#0" class="login-button tariffs__card">
        <div class="tariffs__card-body">
          <div class="tariffs__card-title">1 месяц</div>
          <div class="tariffs__card-pretext">за</div>
          <div class="tariffs__card-price">76 ₽</div>
        </div>
        <div class="tariffs__card-footer">
          <span>начни!</span>
        </div>
      </a>
    </div>
    <div class="tariffs-content__list-item">
      <a v-bind:onclick="pay199" href="#0" class="login-button tariffs__card">
        <div class="tariffs__card-body">
          <div class="tariffs__card-title">3 месяца</div>
          <div class="tariffs__card-pretext">за</div>
          <div class="tariffs__card-price">199 ₽</div>
        </div>
        <div class="tariffs__card-footer">
          <span>выгода</span>
          <div class="tariffs__card-discount">
            <div class="tariffs__card-percent">
              12<span>%</span>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="tariffs-content__list-item" v-if="tariffStatus === 'new'">
      <a v-bind:onclick="pay1" href="#0" class="login-button tariffs__card">
        <div class="tariffs__card-body">
          <div class="tariffs__card-title" style="color: #e5007d">4 дня</div>
          <div class="tariffs__card-pretext">за</div>
          <div class="tariffs__card-price" style="color: #e5007d">1 ₽</div>
        </div>
        <div class="tariffs__card-footer">
          <span>акция</span>
          <div class="tariffs__card-discount">
            <div class="tariffs__card-percent" style="margin-right: 0">
              !!!
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="tariffs-content__list-item">
      <a v-bind:onclick="pay389" href="#0" class="login-button tariffs__card">
        <div class="tariffs__card-body">
          <div class="tariffs__card-title">6 месяцев</div>
          <div class="tariffs__card-pretext">за</div>
          <div class="tariffs__card-price">389 ₽</div>
        </div>
        <div class="tariffs__card-footer">
          <span>выгода</span>
          <div class="tariffs__card-discount">
            <div class="tariffs__card-percent">
              14<span>%</span>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="tariffs-content__list-item">
      <a v-bind:onclick="pay699" href="#0" class="login-button tariffs__card">
        <div class="tariffs__card-body">
          <div class="tariffs__card-title">12 месяцев</div>
          <div class="tariffs__card-pretext">за</div>
          <div class="tariffs__card-price">699 ₽</div>
        </div>
        <div class="tariffs__card-footer">
          <span>выгода</span>
          <div class="tariffs__card-discount">
            <div class="tariffs__card-percent">
              22<span>%</span>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
export default {
  name: 'ListTariffs',
  computed: {
    ...mapState({
      firstName: state => state.userInfo.firstName,
      lastName: state => state.userInfo.lastName,
      hash: state => state.userInfo.hash,
      image: state => state.userInfo.image,
      name: state => state.userInfo.name,
      id: state => state.userInfo.id,
      tariffStatus: state => state.userTariff.tariffStatus,
    }),
    ...mapGetters({
      profileUrl: 'userInfo/profileUrl',
    }),
    pay76() {
      return `pay(
        '${process.env.VUE_APP_CLOUDPAYMENTS_PUBLICKEY}',
        ${this.id},
        76,
        22,
        'month1-76',
        'Тариф 76 рублей за 1 месяц',
        'Тариф 76 рублей за 1 месяц',
        '${this.profileUrl}')`;
    },
    pay199() {
      return `pay(
        '${process.env.VUE_APP_CLOUDPAYMENTS_PUBLICKEY}',
        ${this.id},
        199,
        3*22,
        'month3-199',
        'Тариф 199 рублей за 3 месяца',
        'Тариф 199 рублей за 3 месяца',
        '${this.profileUrl}'
        )`;
    },
    pay1() {
      return `pay(
        '${process.env.VUE_APP_CLOUDPAYMENTS_PUBLICKEY}',
        ${this.id},
        1,
        22,
        'day4-1-promo-228',
        'Тариф 1 рубль за 4 дня',
        'Тариф 76 рублей за 1 месяц',
        '${this.profileUrl}',
        4,
        76
        )`;
    },
    pay389() {
      return `pay(
        '${process.env.VUE_APP_CLOUDPAYMENTS_PUBLICKEY}',
        ${this.id},
        389,
        6*22,
        'month6-389',
        'Тариф 389 рублей за 6 месяцев',
        'Тариф 389 рублей за 6 месяцев',
        '${this.profileUrl}'
        )`;
    },
    pay699() {
      return `pay(
        '${process.env.VUE_APP_CLOUDPAYMENTS_PUBLICKEY}',
        ${this.id},
        699,
        12*22,
        'month12-699',
        'Тариф 699 рублей за 12 месяцев',
        'Тариф 699 рублей за 12 месяцев',
        '${this.profileUrl}'
        )`;
    },
  },
}
</script>
