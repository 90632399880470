<template>
  <div class="top-info">
    <div class="container_center">
      <div class="top-info__content">
        <img class="top-info__logo" src="images/logo.png" alt="">
        <a v-bind:href="buttonExit" class="top-info__button-login">выход</a>
        <div class="top-info__user">
          <div class="top-info__user-img">
            <img v-show="image!=''" v-bind:src="image" alt="">
          </div>
          <div class="top-info__user-info">
            <h3 class="top-info__user-name">{{ fullName }}</h3>
            <p class="top-info__user-login">@{{ name }}</p>
            <p class="top-info__user-id">ID {{ id }}</p>
          </div>
        </div>
        <div class="top-info__middle">
          <picture>
            <source srcset="images/tg-bg-desktop.png" media="(min-width: 768px)">
            <!-- <source srcset="img/svg/logo-tablet.svg" media="(min-width: 768px)"> -->
            <img src="images/tg-bg.png" alt="" class="top-info__middle-img">
          </picture>
          <a v-bind:href="buttonTG" class="top-info__button-tg">Перейти в бот</a>
        </div>
        <p class="top-info__title">Тариф:</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
  name: 'UserInfo',
  data() {
    return {
      buttonTG: 'https://t.me/GoGiBeybot',
      buttonExit: '/',
    }
  },
  computed: {
    ...mapState({
      image: state => state.userInfo.image,
      name: state => state.userInfo.name,
      id: state => state.userInfo.id,
    }),
    ...mapGetters({
      fullName: 'userInfo/fullName',
    }),
  },
  methods: {
    ...mapActions({
      fetchInfo: 'userInfo/fetchInfo',
      fetchTariff: 'userTariff/fetchTariff',
    }),
  },
  async mounted() {
    await this.fetchInfo();
    await this.fetchTariff()
  },
}
</script>
