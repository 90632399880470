<template>
  <header class="header">
    <div class="container_center">
      <p class="header__hint">Личный кабинет</p>
    </div>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',
}
</script>
