<template>
  <div class="tariffs-action">
    <div class="tariffs-action__button">
      <div class="tariffs-action__button-wrapper">
        <router-link 
          v-bind:to="{
            path: '/profile-manage',
            query: {
              id,
              first_name: firstName,
              last_name: lastName,
              photo_url: image,
              username: name,
              auth_date: authDate,
              hash,
            }
          }" 
          class="tariffs-action__button-link"
        >
          управлять тарифом
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ButtonManageTariff',
  computed: {
    ...mapState({
      firstName: state => state.userInfo.firstName,
      lastName: state => state.userInfo.lastName,
      image: state => state.userInfo.image,
      name: state => state.userInfo.name,
      id: state => state.userInfo.id,
      authDate: state => state.userInfo.authDate,
      hash: state => state.userInfo.hash,
    }),
  },
}
</script>
