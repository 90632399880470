<template>
  <div class="tariffs-content__info">
    <div class="tariffs-content__info-wrapper">
      <h3 class="tariffs-content__title"><b>GO!</b> {{ tariffName }}</h3>
      <p class="tariffs-content__price">тариф: {{ tariffName }} / {{ tariffPrice }} {{ rubleText }}</p>
      <p class="tariffs-content__date">подписка до: {{ tariffEndDate }}</p>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  name: 'TariffYes',
  computed: {
    rubleText() {
      const textForms = ['рубль', 'рубля', 'рублей'];
      const n1 = Math.abs(this.tariffPrice) % 100; 
      const n2 = n1 % 10;
      if (n1 > 10 && n1 < 20) { return textForms[2]; }
      if (n2 > 1 && n2 < 5) { return textForms[1]; }
      if (n2 === 1) { return textForms[0]; }
      return textForms[2];
    },
    ...mapState({
      tariffName: state => state.userTariff.tariffName,
      tariffPrice: state => state.userTariff.tariffPrice,
      tariffEndDate: state => state.userTariff.tariffEndDate,
    }),
  },
}
</script>
