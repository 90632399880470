<template>
  <PageHeader />

  <main class="main">
    
    <UserInfo />

    <div class="tariffs-content tariffs-content--empty">
      <div class="container_center">
        <div class="tariffs-content__body">
          <TariffYes v-if="tariffStatus === 'active'" />
          <TariffNo v-else />
        </div>
      </div>
    </div>

    <ButtonManageTariff v-if="tariffStatus === 'active'"  />
    <ButtonSelectTariff v-else />

    <ButtonSupport />
  </main>

  <PageFooter/>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import UserInfo from '@/components/UserInfo.vue';
import TariffYes from '@/components/TariffYes.vue';
import TariffNo from '@/components/TariffNo.vue';
import ButtonManageTariff from '@/components/ButtonManageTariff.vue';
import ButtonSelectTariff from '@/components/ButtonSelectTariff.vue';
import ButtonSupport from '@/components/ButtonSupport.vue';
import PageFooter from '@/components/PageFooter.vue';

import {mapActions, mapState} from 'vuex';

export default {
  name: 'ProfileIndex',

  components: {
    PageFooter,
    UserInfo,
    TariffYes,
    TariffNo,
    ButtonManageTariff,
    ButtonSelectTariff,
    ButtonSupport,
    PageHeader,
  },

  computed: {
    ...mapState({
      tariffStatus: state => state.userTariff.tariffStatus,
    }),
  },
  methods: {
    ...mapActions({
      fetchTariff: 'userTariff/fetchTariff',
    }),
  },
  mounted() {
    document.body.classList.add('page-profile');
  },
  unmounted() {
    document.body.classList.remove('page-profile');
  },
}
</script>
