<template>
  
  <PageHeader />

  <main class="main">
    
    <UserInfo />

    <div class="tariffs-content">
      <div class="container_center">
        <div class="tariffs-content__body">
          <TariffYes v-if="tariffStatus === 'active'" />
          <TariffNo v-else />

          <ListTariffs />
        </div>
      </div>
    </div>

    <div class="tariffs-slogan">
      мощнее тариф – больше выгода
    </div>

    <ButtonSupport />
  </main>

  <PageFooter/>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import UserInfo from '@/components/UserInfo.vue';
import TariffYes from '@/components/TariffYes.vue';
import TariffNo from '@/components/TariffNo.vue';
import ListTariffs from '@/components/ListTariffs.vue';
import ButtonSupport from '@/components/ButtonSupport.vue';
import PageFooter from '@/components/PageFooter.vue';

import {mapState} from 'vuex';

export default {
  name: 'ProfileIndex',

  components: {
    PageFooter,
    UserInfo,
    TariffYes,
    TariffNo,
    ListTariffs,
    ButtonSupport,
    PageHeader,
  },

  computed: {
    ...mapState({
      tariffStatus: state => state.userInfo.tariffStatus,
    }),
  },
  mounted() {
    document.body.classList.add('page-profile');
    document.body.classList.add('page-profile--select');
    
    let paymentScript = document.createElement('script');
    paymentScript.setAttribute('src', 'js/widget.js');
    document.head.appendChild(paymentScript);
  },
  unmounted() {
    document.body.classList.remove('page-profile--select');
    document.body.classList.remove('page-profile');
  },
}
</script>
