<template>
  <!-- Log -->
  <div class="logon-block">
    <button id="login-button" class="login-button header__button get-modal_1">Войти</button>
  </div>

  <!-- Main -->

  <main class="main">
    <!-- begin firstScreen-->
    <section class="firstScreen section" id="firstScreen">
      <div class="firstScreen__content"><img class="logo" src="images/logo.png" alt=""/>
        <div class="fixedBox">
          <div class="fixedBox__item"><a class="button__link" href="https://t.me/GoGiBeybot" target="_blank"><img class="button__img-first wow pulse" data-wow-duration="2s" data-wow-offset="50" data-wow-iteration="100" src="images/button.png" alt=""/></a></div>
        </div>
        <div class="firstScreen__slider firstScreen__slider--left">
          <div class="firstScreen__slide">
            <picture>
              <source srcset="images/slide1-left-mob.png" media="(max-width: 767.98px)"/><img src="images/slide__1-left.png" alt=""/>
            </picture>
          </div>
          <div class="firstScreen__slide">
            <picture>
              <source srcset="images/slide3-left-mob.png" media="(max-width: 767.98px)"/><img src="images/slide__3-left.png" alt=""/>
            </picture>
          </div>
          <div class="firstScreen__slide">
            <picture>
              <source srcset="images/slide4-left-mob.png" media="(max-width: 767.98px)"/><img src="images/slide__4-left.png" alt=""/>
            </picture>
          </div>
        </div>
        <div class="firstScreen__slider firstScreen__slider--right">
          <div class="firstScreen__slide">
            <picture>
              <source srcset="images/slide1-right-mob.png" media="(max-width: 767.98px)"/><img src="images/slide__1-right.png" alt=""/>
            </picture>
          </div>
          <div class="firstScreen__slide">
            <picture>
              <source srcset="images/slide4-right-mob.png" media="(max-width: 767.98px)"/><img src="images/slide__4-right.png" alt=""/>
            </picture>
          </div>
          <div class="firstScreen__slide">
            <picture>
              <source srcset="images/slide3-right-mob.png" media="(max-width: 767.98px)"/><img src="images/slide__3-right.png" alt=""/>
            </picture>
          </div>
        </div>
      </div>
    </section>
    <!-- end firstScreen-->
    <!-- begin instructios-->
    <section class="instructios section" id="instructios">
      <div class="container_center">
        <div class="instructios__content">
          <div class="instructios__left wow fadeInLeft" data-wow-duration="1s" data-wow-offset="150">
              

              
            <div class="item__title">1. СКРИНШОТИМ</div>
            <div class="item__desc">видео в Instagram, Tik-Tok, VK или где угодно и отправляем его Гоги</div><img class="inst__img" src="images/Line_L.png" alt=""/>
          </div>
          <div class="instructios__center wow fadeIn" data-wow-duration="1s" data-wow-offset="250">
            <div class="item__titleCenter">Хей</div>
            <div class="item__descCenter-lite">ДЛЯ ПОИСКА НУЖЕН</div>
            <div class="item__descCenter-bold">ОДИН СКРИНШОТ</div><img class="inst__img" src="images/smart+rabbit.png" alt=""/>
          </div>
          <div class="instructios__right wow fadeInRight" data-wow-duration="1s" data-wow-offset="150">
            <div class="item__title">2. СЕКУНДА ПОИСКА</div>
            <div class="item__desc">Гоги организует точный поиск кино за секунду</div><img class="inst__img" src="images/Line_R.png" alt=""/>
          </div>
        </div>
      </div>
    </section>
    <!-- end instructios-->
    <!-- begin colorBox-->
    <section class="colorBox section" id="colorBox">
      <div class="container_center">
        <div class="colorBox__content">
          <div class="colorBox__plate">
            <div class="colorBox__left"><img src="images/text1.png" alt=""/></div>
            <div class="colorBox__center wow pulse" data-wow-duration="2s" data-wow-offset="250" data-wow-iteration="2">
              <div class="colorBox__center-absolute"><img class="circle__img" src="images/circle.png" alt=""/></div>
            </div>
            <div class="colorBox__right"><img src="images/text2.png" alt=""/></div>
          </div>
          <div class="colorBox__action"><a class="button" href="https://t.me/GoGiBeybot" target="_blank"><img class="button__img wow pulse" data-wow-duration="2s" data-wow-offset="250" data-wow-iteration="100" src="images/button.png" alt=""/></a></div>
        </div>
      </div>
    </section>
    <!-- end colorBox-->
    <!-- begin cinema-->
    <!-- end cinema-->
    <!-- begin step-->
    <section class="step section" id="step">
      <div class="container_center">
        <div class="step__titleBox">
          <div class="step__sectionTitle">Все очень просто!</div>
          <div class="step__arrow"><img class="arrow__img" src="images/Nose.png" alt=""/></div>
        </div>
        <div class="step__content"> 
          <div class="step__item step__item-1">
            <div class="text__box">
              <div class="step__title">ЗАПУСТИТЕ БОТ</div>
              <div class="step__desc">Перейдите официальный бот @GoGiBeyBot</div>
            </div>
          </div>
          <div class="step__item step__item-2">
            <div class="text__box">       
              <div class="step__title">ПОИСК ЗА СЕКУНДУ</div>
              <div class="step__desc">Сделайте поиск: Названию фильма, По описанию фильма, Поиск по скрину</div>
            </div>
          </div>
          <div class="step__item step__item-3">
            <div class="text__box">       
              <div class="step__title">ПРОЙДИТЕ РЕГИСТРАЦИЮ</div>
              <div class="step__desc">Регистрация занимает в среднем 2 минуты</div>
            </div>
          </div>
          <div class="step__item step__item-4">
            <div class="text__box">       
              <div class="step__title">ГОТОВА</div>
              <div class="step__desc">Полный доступ GoGiBey у вас в кармане</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end step-->
    <!-- begin tariffs -->
    <!-- <section class="tariffs section" id="tariffs">
      <div class="tariffs__titleBox">
        <div class="tariffs__sectionTitle">Тарифы <span><span>GO</span>GIBEY:</span></div>
      </div>
      <div class="container_center">
        <div class="tariffs__content"> 
          <div class="tariffs__item tariffs__item-1">

            <a href="#0" class="tariffs__card open-modal-log">
              <div class="tariffs__card-body">
                <div class="tariffs__card-title">1 месяц</div>
                <div class="tariffs__card-pretext">за</div>
                <div class="tariffs__card-price">76 ₽</div>
              </div>
              <div class="tariffs__card-footer">
                <span>начни!</span>
              </div>
            </a>
          </div>
          <div class="tariffs__item tariffs__item-2">
            <a href="#0" class="tariffs__card open-modal-log">
              <div class="tariffs__card-body">
                <div class="tariffs__card-title">3 месяца</div>
                <div class="tariffs__card-pretext">за</div>
                <div class="tariffs__card-price">199 ₽</div>
              </div>
              <div class="tariffs__card-footer">
                <span>выгода</span>
                <div class="tariffs__card-discount">
                  <div class="tariffs__card-percent">
                    12<span>%</span>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="tariffs__item tariffs__item-3">
            <a href="#0" class="tariffs__card open-modal-log">
              <div class="tariffs__card-body">
                <div class="tariffs__card-title" style="color: #e5007d">4 дня</div>
                <div class="tariffs__card-pretext">за</div>
                <div class="tariffs__card-price" style="color: #e5007d">1 ₽</div>
              </div>
              <div class="tariffs__card-footer">
                <span>акция</span>
                <div class="tariffs__card-discount">
                  <div class="tariffs__card-percent" style="margin-right: 0">
                    !!!
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="tariffs__item tariffs__item-4">
            <a href="#0" class="tariffs__card open-modal-log">
              <div class="tariffs__card-body">
                <div class="tariffs__card-title">6 месяцев</div>
                <div class="tariffs__card-pretext">за</div>
                <div class="tariffs__card-price">389 ₽</div>
              </div>
              <div class="tariffs__card-footer">
                <span>выгода</span>
                <div class="tariffs__card-discount">
                  <div class="tariffs__card-percent">
                    14<span>%</span>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="tariffs__item tariffs__item-5">
            <a href="#0" class="tariffs__card open-modal-log">
              <div class="tariffs__card-body">
                <div class="tariffs__card-title">12 месяцев</div>
                <div class="tariffs__card-pretext">за</div>
                <div class="tariffs__card-price">699 ₽</div>
              </div>
              <div class="tariffs__card-footer">
                <span>выгода</span>
                <div class="tariffs__card-discount">
                  <div class="tariffs__card-percent">
                    22<span>%</span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section> -->
    <!-- end tariffs -->
    <!-- begin bottomBox-->
    <section class="bottomBox section" id="bottomBox">
      <div class="container_center">
        <div class="bottomBox__content">
          <div class="bottomBox__left"><img class="bottom__logo" src="images/logo.png" alt=""/></div>
          <div class="bottomBox__center"><a class="bottom__button" href="https://t.me/GoGiBeybot" target="_blank"><img class="button__img wow pulse" data-wow-duration="2s" data-wow-offset="50" data-wow-iteration="100" src="images/button.png" alt=""/></a></div>
          <div class="bottomBox__right">
            <div class="tg__bot">ТЕЛЕГРАМ-БОТ</div>
            <div class="bot__name">GOGIBEY</div>
            <div class="tg__desc">© все права защищены</div>
          </div>
        </div>
      </div>
    </section>
    <!-- end bottomBox-->
  </main>

  <PageFooter />

  <!-- Modal -->

  <div class="modal modal--log">
    <div class="fullScreen">
      <div class="modal__box">

        <!-- Close -->

        <button class="modal__close"></button>

        <!-- Head -->

        <div class="modal__head">
          <p>АВТОРИЗАЦИЯ</p>
        </div>

        <!-- Body -->

        <div class="modal__body">
          <p class="modal__txt">необходимо согласие с правилами и документами сервиса:</p>
          <div class="check">
            <div class="modal__row">
              <label class="check__item">
                <input type="checkbox" v-model="check1"/>
                <span class="check__mark"></span>
                <span class="check__txt">договор оферты</span>
              </label>
              <span class="modal__link"><a href="pdf/oferta.pdf" target="_blank">ознакомиться</a></span>
            </div>
            <div class="modal__row">
              <label class="check__item">
                <input type="checkbox" v-model="check2"/>
                <span class="check__mark"></span>
                <span class="check__txt">согласие на подписку</span>
              </label>
              <span class="modal__link"><a href="pdf/politic.pdf" target="_blank">ознакомиться</a></span>
            </div>
            <div class="modal__row">
              <label class="check__item">
                <input type="checkbox" v-model="check3"/>
                <span class="check__mark"></span>
                <span class="check__txt">услуги, стоимость и порядок оплаты</span>
              </label>
              <span class="modal__link"><a href="pdf/services.pdf" target="_blank">ознакомиться</a></span>
            </div>
          </div>
          <!-- <div class="modal__btn"><a href="#" class="btn btn--blue">ВОЙТИ ЧЕРЕЗ TELEGRAM</a></div> -->
          <div class="modal__btn" v-show="check1*check2*check3">
            <div ref="telegram"></div>
          </div>
<!-- <script type="text/javascript">
  function onTelegramAuth(user) {
    alert('Logged in as ' + user.first_name + ' ' + user.last_name + ' (' + user.id + (user.username ? ', @' + user.username : '') + ')');
  }
</script> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PageFooter from '@/components/PageFooter.vue';

const botName = process.env.VUE_APP_BOT_NAME;
const profileUrl = process.env.VUE_APP_SITE_PROFILE;

export default {
  name: 'SiteIndex',
  components: {
    PageFooter,
  },
  data() {
    return {
      check1: false,
      check2: false,
      check3: false,
    }
  },
  created() {
    let recaptchaScript1 = document.createElement('script');
    recaptchaScript1.async = false;
    recaptchaScript1.setAttribute('src', 'js/jquery.js');
    document.head.appendChild(recaptchaScript1);
    let recaptchaScript2 = document.createElement('script');
    recaptchaScript2.async = false;
    recaptchaScript2.setAttribute('src', 'js/slick.min.js');
    document.head.appendChild(recaptchaScript2);
    let recaptchaScript3 = document.createElement('script');
    recaptchaScript3.async = false;
    recaptchaScript3.setAttribute('src', 'js/wow.min.js');
    document.head.appendChild(recaptchaScript3);
    let recaptchaScript4 = document.createElement('script');
    recaptchaScript4.async = false;
    recaptchaScript4.setAttribute('src', 'js/script.js');
    document.head.appendChild(recaptchaScript4);
  },
  mounted() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://telegram.org/js/telegram-widget.js?19';
    script.setAttribute('data-size', "large");
    // script.setAttribute('data-userpic', this.userpic);
    script.setAttribute('data-telegram-login', botName);
    script.setAttribute('data-request-access', "write");
    // if (this.radius) { script.setAttribute('data-radius', this.radius); }
    // if (this.mode === 'callback') {
    //   window.onTelegramAuth = this.onTelegramAuth;
    //   script.setAttribute('data-onauth', 'window.onTelegramAuth(user)');
    // } else {
    script.setAttribute('data-auth-url', profileUrl);
    // }
    this.$refs.telegram.appendChild(script);
  },
}
</script>
