import { createRouter, createWebHistory } from 'vue-router';
import SiteIndex from '@/pages/SiteIndex.vue';
import ProfileIndex from '@/pages/ProfileIndex.vue';
import ManageTariff from '@/pages/ManageTariff.vue';
import SelectTariff from '@/pages/SelectTariff.vue';

const routes = [
  {
    path: '/',
    component: SiteIndex,
  },
  {
    path: '/profile',
    component: ProfileIndex,
  },
  {
    path: '/profile-manage',
    component: ManageTariff,
  },
  {
    path: '/profile-list-tariffs',
    component: SelectTariff,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
