<template>
  <div class="tariffs-content__info">
    <div class="tariffs-content__info-wrapper">
      <h3 class="tariffs-content__title">без тарифа</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TariffNo',
}
</script>